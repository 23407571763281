import React from 'react'
import { HashLink } from 'react-router-hash-link'

const Footer = () => {
  return (
   <footer id="contact">
    <div>
      <h1>MUGGLE</h1>
      <p>@all rigth reserved</p>
    </div>

    <div>
      <h5>Follow Us</h5>
      <div>
        <a href="https://youtube.com" target="blank">Youtube</a>
        <a href="https://instagram.com" target ="blank">Instagram</a>
        <a href="https://github.com" target="blank">Github</a>
      </div>
    </div>
   </footer>
  )
}

export default Footer