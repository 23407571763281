import React from 'react'

import  vg  from "../assets/2.webp";

import {AiFillGoogleCircle, AiFillAmazonCircle,AiFillYoutube,AiFillInstagram} from "react-icons/ai";

const Home = () => {
  return (
    <>
    <div class="home" id ="home">
        <main>
            <h1>MUGGLE</h1>
            <p>Soution to all your problems</p>
        </main>
    </div>

    <div className="home2">
        <img src={vg} alt="Graphics" />
        <div>
            <p>We are your one and onlly solution to the tech problems you face everyday. We are leading tech 
                company whoose aim is to increase the problem solving ability in children.
            </p>
        </div>
    </div>

    <div className="home3" id="about">
        <div>
            <h1>Who we are?</h1>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iste, accusamus quam accusantium praesentium
             omnis adipisci commodi, sequi, totam enim dolorem aut saepe culpa non hic exercitationem laudantium ducimus cupiditate pariatur?
             </p>
        </div>
    </div>

    <div className="home4" id="brands">
        <div>
            <h1>Brands</h1>
            <article>
                <div style={{
                    animationDelay:"0.3s",
                }}>
                    <AiFillGoogleCircle/>
                    <p>Google</p>
                </div>
                <div style={{
                    animationDelay:"0.5s",
                }}>
                    <AiFillAmazonCircle/>
                    <p>Amazon</p>
                </div>
                <div style={{
                    animationDelay:"0.7s",
                }}>
                    <AiFillYoutube/>
                    <p>Youtube</p>
                </div>
                <div style={{
                    animationDelay:"1s",
                }}>
                    <AiFillInstagram/>
                    <p>Instagram</p>
                </div>
            </article>
        </div>
    </div>
    </>
  )
}

export default Home